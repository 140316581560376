import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import logoUe from "../styles/logoUe.module.css"

const LogoUe = ({ width }) => {
  const data = useStaticQuery(graphql`
    query {
      logoFe: file(relativePath: { eq: "uelogo-fe.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logoPl: file(relativePath: {eq: "uelogo-pl.png"}){
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logoNcbr: file(relativePath: {eq: "uelogo-ncbr.png"}){
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logoUe: file(relativePath: {eq: "uelogo-ue.png"}){
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className={logoUe.container}>
      <Img fluid={data.logoFe.childImageSharp.fluid}
           className={logoUe.img}
           imgStyle={{ objectFit: "contain" }}/>
      <Img fluid={data.logoPl.childImageSharp.fluid}
           className={logoUe.img}
           imgStyle={{ objectFit: "contain" }}/>
      <Img fluid={data.logoNcbr.childImageSharp.fluid}
           className={logoUe.img}
           imgStyle={{ objectFit: "contain" }}/>
      <Img fluid={data.logoUe.childImageSharp.fluid}
           className={logoUe.img}
           imgStyle={{ objectFit: "contain" }}/>
    </div>

  )
}

export default LogoUe
