import React from "react"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { FormattedMessage } from "gatsby-plugin-intl"
import Map from "./main-map"
import BackgroundImage from "gatsby-background-image"
import contact from "../styles/contact.module.css"

const Contact = ({ node }) => {
  const options = {
    renderNode: {
      [BLOCKS.HEADING_3]: (node, children) => <h3>{children}</h3>,
      [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
    },
  }

  return (
    <BackgroundImage
      fluid={node.contactBackground.fluid}
      backgroundColor={"#000"}
      className={contact.section}
      style={{ backgroundSize: "cover", backgroundPosition: "center" }}
    >
      <div id={"contact"} style={{ position: "absolute", top: "-5rem" }} />
      <div className={contact.sectionGradient} />
      <div className={contact.wrapper}>
        <div className={contact.form}>
          {documentToReactComponents(node.contactInfo.json)}
          <form
            name="sdcontact"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="sdcontact" />

            <p>
              <label>
                {" "}
                <FormattedMessage id="contact.name" />{" "}
              </label>
              <input type="text" name="name" />
            </p>
            <p>
              <label>
                {" "}
                <FormattedMessage id="contact.email" />{" "}
              </label>
              <input type="email" name="email" />
            </p>
            <p>
              <label>
                {" "}
                <FormattedMessage id="contact.title" />{" "}
              </label>
              <input type="text" name="name" />
            </p>
            <p>
              <label>
                {" "}
                <FormattedMessage id="contact.content" />{" "}
              </label>
              <textarea name="name" />
            </p>
            <div className={contact.checkbox}>
              <input
                type="checkbox"
                id="consent"
                name="consent"
                value="consent"
                required={true}
              />
              <label htmlFor="consent" data-content="consent">
                {documentToReactComponents(node.contactDisclaimer.json)}
              </label>
            </div>
            <p>
              <button type="submit">
                <FormattedMessage id="contact.submit" />
              </button>
            </p>
          </form>
        </div>
        <div className={contact.address}>
          {documentToReactComponents(node.contactAdress.json, options)}
        </div>
      </div>
      <div className={contact.mapContainer}>
        <Map />
      </div>
    </BackgroundImage>
  )
}

export default Contact
