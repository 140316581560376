import React from "react";

function HeroHedron(props) {
  const vector = (
    <svg
      width="68.2mm"
      height="75.9mm"
      version="1.1"
      viewBox="0 0 68.2 75.9"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill="none" stroke="#000" strokeWidth=".265">
        <path d="m37.5 0.442 26.4 17.2a0.742 0.742 107 0 1-0.406 1.36l-25.6-0.0123a2.5 2.5 45 0 1-2.5-2.5l4.22e-4 -14.9a1.35 1.35 152 0 1 2.09-1.13z" />
        <path d="m50.6 54.7h-32.9a1.43 1.43 60.2 0 1-1.23-2.16l16.4-27.9a1.47 1.47 180 0 1 2.53-1.8e-5l16.4 27.9a1.43 1.43 120 0 1-1.23 2.16z" />
        <path d="m38.6 21.2h25.7a1.75 1.75 55 0 1 1.65 2.35l-10 27.6a1.18 1.18 175 0 1-2.12 0.194l-16.4-27.9a1.43 1.43 120 0 1 1.23-2.16z" />
        <path d="m17.6 56.9h32.9a1.07 1.07 66.8 0 1 0.777 1.81l-15.5 16.3a2.38 2.38 180 0 1-3.45 4.12e-4l-15.5-16.3a1.07 1.07 113 0 1 0.777-1.81z" />
        <path d="m57.6 56.9h6.72a0.741 0.741 73.5 0 1 0.404 1.36l-26.5 17.2a0.294 0.294 50.3 0 1-0.373-0.449l15.5-16.3a5.83 5.83 157 0 1 4.22-1.81z" />
        <path d="m65.6 54.7h-6.72a1.75 1.75 55 0 1-1.65-2.35l10-27.6a0.44 0.44 9.98 0 1 0.854 0.15v27.3a2.5 2.5 135 0 1-2.5 2.5z" />
        <path d="m30.8 0.442-26.4 17.2a0.742 0.742 73.4 0 0 0.406 1.36l25.6-0.0123a2.5 2.5 135 0 0 2.5-2.5l-4.22e-4 -14.9a1.35 1.35 28.4 0 0-2.09-1.13z" />
        <path d="m29.6 21.2h-25.7a1.75 1.75 125 0 0-1.65 2.35l10 27.6a1.18 1.18 5.24 0 0 2.12 0.194l16.4-27.9a1.43 1.43 60.2 0 0-1.23-2.16z" />
        <path d="m10.6 56.9h-6.72a0.741 0.741 107 0 0-0.404 1.36l26.5 17.2a0.294 0.294 130 0 0 0.373-0.449l-15.5-16.3a5.83 5.83 23.2 0 0-4.22-1.81z" />
        <path d="m2.63 54.7h6.72a1.75 1.75 125 0 0 1.65-2.35l-10-27.6a0.44 0.44 170 0 0-0.854 0.15v27.3a2.5 2.5 45 0 0 2.5 2.5z" />
      </g>
    </svg>
  );

  return <div className={props.style}>{vector}</div>;
}

export default HeroHedron;
