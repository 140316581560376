import React from "react"
import LogoUe from "./logo-ue"
import { INLINES } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import info from "../styles/info.module.css"
import LogoIcon from "./logo-icon"
import { FormattedMessage } from "gatsby-plugin-intl/index"

const Info = ({ node }) => {
  const options = {
    renderNode: {
      [INLINES.ASSET_HYPERLINK]: (node, children) => (
        <a href={node.data.target.fields.file.pl.url}>{children}</a>
      ),
    },
  }

  return (
    <div className={info.section}>
      <div className={info.wrapper}>
        <div className={info.element}>
          <div className={info.ueLogoBar}>
            <LogoUe />
          </div>
          {documentToReactComponents(node.projectInfo.json)}
          <div className={info.logo}>
            <LogoIcon width={"1.8rem"} />
            <FormattedMessage id="title" />
          </div>
        </div>

        {node.orderList.map((item, index) => (
          <div className={info.element} key={index}>
            <div className={info.divider} />
            <div className={info.ueLogoBar}>
              <LogoUe />
            </div>
            {documentToReactComponents(item.orders.json, options)}
            <div className={info.logo}>
              <LogoIcon width={"1.8rem"} />
              <FormattedMessage id="title" />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Info
