import React from "react"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import BackgroundImage from "gatsby-background-image"
import content from "../styles/content.module.css"

const MainContent = ({ node }) => {

  const classNames = require("classnames")

  const options = {
    renderNode: {
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3>{children}</h3>
      ),
      [BLOCKS.HEADING_5]: (node, children) => (
        <h5>{children}</h5>
      ),
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p>{children}</p>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => (
        <li>{children}</li>
      )
    }
  }

  return (
    <BackgroundImage
      fluid={node.contentBackground.fluid}
      backgroundColor={"#000"}
      className={content.section}
      style={{ backgroundSize: "cover", backgroundPosition: "center" }}
    >


      <div className={content.sectionGradient}/>
      <div className={content.wrapper}>
        <div id={"about"} style={{ position: "absolute", top: "-5rem" }}/>

        <div className={content.text}>
          {documentToReactComponents(node.about.json, options)}
          {node.aboutList.map((item, index) => (
            <div key={index}>
              <img src={item.aboutIcon.file.url} alt={"about-icon"}/>
              {documentToReactComponents(item.aboutDescription.json, options)}
            </div>
          ))}
        </div>
        <div className={content.image}>
          <div className={content.inside}>

            <BackgroundImage
              fluid={node.aboutImage.fluid}
              backgroundColor={"#000"}
              style={{
                height: "100%",
                backgroundSize: "cover",
                backgroundPosition: "center"
              }}/>
          </div>
        </div>
      </div>


      <div className={content.wrapper}>
        <div id={"technology"} style={{ position: "absolute", top: "-5rem" }}/>

        <div className={content.text}>
          {documentToReactComponents(node.technology.json, options)}
        </div>
        <div className={classNames([content.image, content.imageLeft])}>
          <div className={content.inside}>
            <BackgroundImage
              fluid={node.technologyImage.fluid}
              backgroundColor={"#000"}
              style={{
                height: "100%",
                backgroundSize: "cover",
                backgroundPosition: "center"
              }}/>
          </div>
        </div>
      </div>
      <div className={content.wrapper}>
        <div className={content.text}>
          {documentToReactComponents(node.solutions.json, options)}
        </div>
        <div className={content.image}>
          <div className={content.inside}>
            <BackgroundImage
              fluid={node.solutionsImage.fluid}
              backgroundColor={"#000"}
              style={{
                height: "100%",
                backgroundSize: "cover",
                backgroundPosition: "center"
              }}/>
          </div>
        </div>
      </div>

      <div className={content.servicesWrapper}>
        <div id={"services"} style={{ position: "absolute", top: "-5rem" }}/>

        {documentToReactComponents(node.services.json, options)}

        {node.serviceList.map((item, index) => (
          <div className={content.serviceBox} key={index}>
            <div className={content.serviceContent}>
              <BackgroundImage fluid={item.background.fluid}
                               backgroundColor={"#000"}
                               style={{ backgroundSize: "cover", backgroundPosition: "center" }}>
                <p>{item.title}</p>

              </BackgroundImage>
              <div className={content.serviceText}>
               {documentToReactComponents(item.description.json, options)}
              </div>
            </div>
          </div>
        ))}
      </div>

    </BackgroundImage>
  )
}

export default MainContent