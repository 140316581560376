import React from "react"
import { graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/main-hero"
import MainContent from "../components/main-content"
import Contact from "../components/main-contact"
import Info from "../components/main-info"

const IndexPage = ({ data, intl }) => {
  const node = data.allContentfulMain.nodes.slice(0, 1).pop()
  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: "title" })}
        description={intl.formatMessage({ id: "description" })}
      />
      <Hero node={node} />
      <MainContent node={node} />
      <Contact node={node} />
      <Info node={node} />
    </Layout>
  )
}

export const query = graphql`
  query ContentFulMains($locale: String) {
    allContentfulMain(filter: { node_locale: { eq: $locale } }) {
      nodes {
        contentful_id
        title
        path
        heroBackground {
          fluid(maxWidth: 3000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        contentBackground {
          fluid(maxWidth: 3000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        contactBackground {
          fluid(maxWidth: 3000) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        about {
          json
        }
        aboutList {
          aboutDescription {
            json
          }
          aboutIcon {
            file {
              url
            }
          }
        }
        aboutImage {
          fluid(maxWidth: 1500) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        solutions {
          json
        }
        solutionsImage {
          fluid(maxWidth: 1500) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        technology {
          json
        }
        technologyImage {
          fluid(maxWidth: 1500) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        services {
          json
        }
        serviceList {
          path
          title
          background {
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          description {
            json
          }
        }
        contactInfo {
          json
        }
        contactAdress {
          json
        }
        contactDisclaimer {
          json
        }
        projectInfo {
          json
        }
        orderList {
          titles
          orders {
            json
          }
          ordermedia {
            file {
              fileName
              url
            }
          }
        }
      }
    }
  }
`

export default injectIntl(IndexPage)
