import React from "react";

function HeroLogo(props) {

  return (
    <div className={props.style}>
      <svg
        width="242.49mm"
        height="13.448mm"
        version="1.1"
        viewBox="0 0 242.49 13.448"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g transform="translate(-49.6 -36.365)" stroke="#000">
          <g strokeWidth=".1">
            <path
              d="m67.082 36.416h3.0411l6.7009 7.9839 6.7009-7.9839h3.0411v13.346h-2.6513v-9.7877l-7.0906 8.4483-7.0906-8.4483 1e-6 9.7877h-2.6513z"
              fillRule="evenodd"
            />
            <path
              d="m102.3 49.762h3.056l-7.215-13.346h-2.7521l-7.215 13.346h3.056l5.535-10.239z"
              fillRule="evenodd"
            />
            <path
              d="m118.27 40.794c0 0.99925-0.81005 1.8093-1.8093 1.8093h-6.7357v-3.6186h6.7357c0.99925 0 1.8093 0.81005 1.8093 1.8093"
              fill="none"
              strokeLinecap="round"
            />
          </g>
          <g fillRule="evenodd">
            <path
              d="m131.3 49.762v-10.659h5.8575v-2.6871h-14.475v2.6871h5.8575v10.659h1.3802z"
              strokeWidth=".1"
            />
            <rect
              x="139.2"
              y="47.078"
              width="2.6773"
              height="2.6773"
              strokeLinecap="round"
              strokeWidth=".1"
            />
            <g>
              <g strokeWidth=".1">
                <path
                  d="m146.53 39.099h6.314c1.654 0 2.9856 1.3316 2.9856 2.9856v2.0072c0 1.654-1.3316 2.9856-2.9856 2.9856h-6.314zm-2.7502-2.6827h9.201c3.0603 0 5.524 2.4637 5.524 5.5239v2.2984c0 3.0603-2.4637 5.5239-5.524 5.5239h-9.201z"
                  strokeLinecap="round"
                />
                <rect
                  x="160.11"
                  y="36.416"
                  width="2.8262"
                  height="13.346"
                  strokeLinecap="round"
                />
                <path d="m164.68 36.416h3.0411l6.7009 7.9839 6.7009-7.9839h3.0411v13.346h-2.6513v-9.7877l-7.0906 8.4483-7.0906-8.4483v9.7877h-2.6513z" />
              </g>
              <g strokeLinecap="round" strokeWidth=".09946">
                <rect
                  transform="rotate(90)"
                  x="47.036"
                  y="-199.53"
                  width="2.7273"
                  height="13.681"
                />
                <rect
                  transform="rotate(90)"
                  x="36.416"
                  y="-199.53"
                  width="2.7273"
                  height="13.681"
                />
                <rect
                  transform="rotate(90)"
                  x="41.724"
                  y="-199.53"
                  width="2.7273"
                  height="13.681"
                />
              </g>
              <path
                d="m214.73 46.524v-10.108h2.7188v13.348l-3.2209-9.5e-4 -10.344-10.14v10.141h-2.7188v-13.348l3.2559 9.6e-4z"
                strokeWidth=".1"
              />
              <path
                d="m271.95 46.524v-10.108h2.7188v13.348l-3.2209-9.5e-4 -10.344-10.14v10.141h-2.7188v-13.348l3.2559 9.6e-4z"
                strokeWidth=".1"
              />
              <g strokeLinecap="round">
                <rect
                  x="236.62"
                  y="36.416"
                  width="2.8262"
                  height="13.346"
                  strokeWidth=".1"
                />
                <path
                  d="m251.02 39.1c1.654 0 2.9856 1.3316 2.9856 2.9856v2.0072c0 1.654-1.3316 2.9856-2.9856 2.9856h-4.2001c-1.654 0-2.9856-1.3316-2.9856-2.9856v-2.0072c0-1.654 1.3316-2.9856 2.9856-2.9856h4.2001zm0.17432-2.6839c3.0603 0 5.524 2.4637 5.524 5.5239v2.2984c0 3.0603-2.4637 5.5239-5.524 5.5239h-4.5487c-3.0603 0-5.524-2.4637-5.524-5.5239v-2.2984c0-3.0603 2.4637-5.5239 5.524-5.5239h4.5487z"
                  strokeWidth=".1"
                />
                <g strokeWidth=".083714">
                  <path d="m223.21 44.448c-2.22 0-4.0197-1.7997-4.0197-4.0197 0-2.22 1.7997-4.0196 4.0197-4.0196l11.063 1e-5v2.7273l-11.156-1e-5c-0.71652 0-1.2995 0.5786-1.2995 1.2924s0.58296 1.2924 1.3021 1.2924c2.5849-3.5e-4 5.1697-7.1e-4 7.7546-1e-3 2.22 0 4.0197 1.7997 4.0197 4.0197s-1.7997 4.0197-4.0197 4.0197l-11.296-1e-5v-2.7273l11.39 1e-5c0.71652 0 1.2995-0.5786 1.2995-1.2924s-0.58296-1.2924-1.3021-1.2924c-2.5849 3.4e-4 -5.1697 7.1e-4 -7.7546 1e-3z" />
                  <path d="m280.37 44.448c-2.22 0-4.0197-1.7997-4.0197-4.0197 0-2.22 1.7997-4.0196 4.0197-4.0196l11.063 1e-5v2.7273l-11.156-1e-5c-0.71652 0-1.2995 0.5786-1.2995 1.2924s0.58296 1.2924 1.3021 1.2924c2.5849-3.5e-4 5.1697-7.1e-4 7.7546-1e-3 2.22 0 4.0197 1.7997 4.0197 4.0197s-1.7997 4.0197-4.0197 4.0197l-11.296-1e-5v-2.7273l11.39 1e-5c0.71652 0 1.2995-0.5786 1.2995-1.2924s-0.58296-1.2924-1.3021-1.2924c-2.5849 3.4e-4 -5.1697 7.1e-4 -7.7546 1e-3z" />
                  <path d="m53.661 44.448c-2.22 0-4.0197-1.7997-4.0197-4.0197 0-2.22 1.7997-4.0196 4.0197-4.0196l11.063 1e-5v2.7273l-11.156-1e-5c-0.71652 0-1.2995 0.5786-1.2995 1.2924s0.58296 1.2924 1.3021 1.2924c2.5849-3.5e-4 5.1697-7.1e-4 7.7546-1e-3 2.22 0 4.0197 1.7997 4.0197 4.0197s-1.7997 4.0197-4.0197 4.0197l-11.296-1e-5v-2.7273l11.39 1e-5c0.71652 0 1.2995-0.5786 1.2995-1.2924s-0.58296-1.2924-1.3021-1.2924c-2.5849 3.4e-4 -5.1697 7.1e-4 -7.7546 1e-3z" />
                </g>
              </g>
              <g strokeWidth=".1">
                <path d="m67.082 36.416h3.0411l6.7009 7.9839 6.7009-7.9839h3.0411v13.346h-2.6513v-9.7877l-7.0906 8.4483-7.0906-8.4483 1e-6 9.7877h-2.6513z" />
                <path d="m102.3 49.762h3.056l-7.215-13.346h-2.7521l-7.215 13.346h3.056l5.535-10.239z" />
                <path
                  d="m118.27 40.794c0 0.99925-0.81005 1.8093-1.8093 1.8093h-6.7357v-3.6186h6.7357c0.99925 0 1.8093 0.81005 1.8093 1.8093m2.5685 0c0 1.7456-1.0217 3.2526-2.504 3.9577l2.7035 5.0108h-3.0395l-2.4817-4.5906h-5.7918v4.5906h-2.6601v-13.346l9.3962-1e-5c2.4175 0 4.3775 1.96 4.3775 4.3778z"
                  strokeLinecap="round"
                />
                <path d="m131.3 49.762v-10.659h5.8575v-2.6871h-14.475v2.6871h5.8575v10.659h1.3802z" />
                <rect
                  x="139.2"
                  y="47.078"
                  width="2.6773"
                  height="2.6773"
                  strokeLinecap="round"
                />
                <rect
                  x="160.11"
                  y="36.416"
                  width="2.8262"
                  height="13.346"
                  strokeLinecap="round"
                />
                <path d="m164.68 36.416h3.0411l6.7009 7.9839 6.7009-7.9839h3.0411v13.346h-2.6513v-9.7877l-7.0906 8.4483-7.0906-8.4483v9.7877h-2.6513z" />
              </g>
              <g strokeLinecap="round" strokeWidth=".09946">
                <rect
                  transform="rotate(90)"
                  x="47.036"
                  y="-199.53"
                  width="2.7273"
                  height="13.681"
                />
                <rect
                  transform="rotate(90)"
                  x="36.416"
                  y="-199.53"
                  width="2.7273"
                  height="13.681"
                />
                <rect
                  transform="rotate(90)"
                  x="41.724"
                  y="-199.53"
                  width="2.7273"
                  height="13.681"
                />
              </g>
              <path
                d="m214.73 46.524v-10.108h2.7188v13.348l-3.2209-9.5e-4 -10.344-10.14v10.141h-2.7188v-13.348l3.2559 9.6e-4z"
                strokeWidth=".1"
              />
              <path
                d="m271.95 46.524v-10.108h2.7188v13.348l-3.2209-9.5e-4 -10.344-10.14v10.141h-2.7188v-13.348l3.2559 9.6e-4z"
                strokeWidth=".1"
              />
              <g strokeLinecap="round">
                <rect
                  x="236.62"
                  y="36.416"
                  width="2.8262"
                  height="13.346"
                  strokeWidth=".1"
                />
                <g strokeWidth=".083714">
                  <path d="m223.21 44.448c-2.22 0-4.0197-1.7997-4.0197-4.0197 0-2.22 1.7997-4.0196 4.0197-4.0196l11.063 1e-5v2.7273l-11.156-1e-5c-0.71652 0-1.2995 0.5786-1.2995 1.2924s0.58296 1.2924 1.3021 1.2924c2.5849-3.5e-4 5.1697-7.1e-4 7.7546-1e-3 2.22 0 4.0197 1.7997 4.0197 4.0197s-1.7997 4.0197-4.0197 4.0197l-11.296-1e-5v-2.7273l11.39 1e-5c0.71652 0 1.2995-0.5786 1.2995-1.2924s-0.58296-1.2924-1.3021-1.2924c-2.5849 3.4e-4 -5.1697 7.1e-4 -7.7546 1e-3z" />
                  <path d="m280.37 44.448c-2.22 0-4.0197-1.7997-4.0197-4.0197 0-2.22 1.7997-4.0196 4.0197-4.0196l11.063 1e-5v2.7273l-11.156-1e-5c-0.71652 0-1.2995 0.5786-1.2995 1.2924s0.58296 1.2924 1.3021 1.2924c2.5849-3.5e-4 5.1697-7.1e-4 7.7546-1e-3 2.22 0 4.0197 1.7997 4.0197 4.0197s-1.7997 4.0197-4.0197 4.0197l-11.296-1e-5v-2.7273l11.39 1e-5c0.71652 0 1.2995-0.5786 1.2995-1.2924s-0.58296-1.2924-1.3021-1.2924c-2.5849 3.4e-4 -5.1697 7.1e-4 -7.7546 1e-3z" />
                  <path d="m53.661 44.448c-2.22 0-4.0197-1.7997-4.0197-4.0197 0-2.22 1.7997-4.0196 4.0197-4.0196l11.063 1e-5v2.7273l-11.156-1e-5c-0.71652 0-1.2995 0.5786-1.2995 1.2924s0.58296 1.2924 1.3021 1.2924c2.5849-3.5e-4 5.1697-7.1e-4 7.7546-1e-3 2.22 0 4.0197 1.7997 4.0197 4.0197s-1.7997 4.0197-4.0197 4.0197l-11.296-1e-5v-2.7273l11.39 1e-5c0.71652 0 1.2995-0.5786 1.2995-1.2924s-0.58296-1.2924-1.3021-1.2924c-2.5849 3.4e-4 -5.1697 7.1e-4 -7.7546 1e-3z" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </div>
  );
}

export default HeroLogo;
