 import React from "react"
import BackgroundImage from "gatsby-background-image"
import LogoUe from "./logo-ue"
import HeroLogo from "./main-hero-logo"
import HeroHedron from "./main-hero-hedron"
import hero from "../styles/hero.module.css"


const Hero = ({ node }) => (
  <div className={hero.section} id={"hero"}>
    <BackgroundImage
      fluid={node.heroBackground.fluid}
      backgroundColor={"#000"}
      style={{
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundSize: "cover",
        backgroundPosition: "center"
      }}
    >
      <div className={hero.sectionGradient}/>

    </BackgroundImage>
    <div className={hero.container}>
      <div className={hero.ueLogoBar}>
        <LogoUe/>
      </div>

      <div className={hero.logoSpace}>
        <div className={hero.logoBg}>
          <HeroHedron style={hero.hedronSolid}/>
          <HeroHedron style={hero.hedronOutlinePrimary}/>
          <HeroHedron style={hero.hedronOutlineSecondary}/>

        </div>

        <div className={hero.logoFgContainer}>
          <HeroLogo style={hero.logoOutlinePrimary}/>
          <HeroLogo style={hero.logoOutlineSecondary}/>
          <HeroLogo style={hero.logoSolid}/>
        </div>
      </div>
    </div>
  </div>

)

export default Hero